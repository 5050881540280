import React, { useContext, useState } from 'react';
import { Button, Form, Alert, Row, Col } from 'react-bootstrap';
import AuthContext from 'contexts/AuthContext';
import { loginUser } from 'helpers/requests/users';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const LoginForm = ({ next }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await loginUser(data.email, data.password);
    if (r.success) {
      setIsAuthenticated(true);
      navigate(next);
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          name="email"
          type="email"
          isInvalid={!!errors.email}
          {...register('email', {
            required: 'Enter your email address',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Enter a valid email address'
            }
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Password'}
          name="password"
          type="password"
          isInvalid={!!errors.password}
          {...register('password', {
            required: 'Enter your password'
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="justify-content-between align-items-center mb-3">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input type="checkbox" name="remember" defaultChecked />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link className="fs--1 mb-0" to={appPaths.auth.forgotPassword}>
            Forgot Password?
          </Link>
        </Col>
      </Row>

      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="w-100"
          disabled={submittingForm}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;

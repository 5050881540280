import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { sendPasswordResetLink } from 'helpers/requests/users';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const ForgotPasswordForm = ({ setEmailSent }) => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await sendPasswordResetLink(data.email);
    if (r.success) {
      setEmailSent(true);
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          name="email"
          type="email"
          isInvalid={!!errors.email}
          {...register('email', {
            required: 'Enter your email address',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Enter a valid email address'
            }
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email?.message}
        </Form.Control.Feedback>
      </Form.Group>

      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}

      <Form.Group>
        <Button className="w-100" type="submit" disabled={submittingForm}>
          Send reset link
        </Button>
      </Form.Group>
    </Form>
  );
};

export default ForgotPasswordForm;

import React from 'react';
import { appPaths } from 'config';
import { Col } from 'react-bootstrap';
import InfoCard from 'components/stats/generic/InfoCard';

const AgentsInfo = ({ count }) => {
  return (
    <Col key={'Agents'} sm={6}>
      <InfoCard
        title={'Agents'}
        count={count}
        viewLink={appPaths.agents.list}
        createLink={appPaths.agents.create}
      />
    </Col>
  );
};

export default AgentsInfo;

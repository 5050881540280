import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import is from 'is_js';
import MainLayout from 'layouts/MainLayout';

import ErrorLayout from 'layouts/ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/common/Error404';

import Login from 'components/authentication/login/Login';
import Registration from 'components/authentication/registration/Registration';
import ForgotPassword from 'components/authentication/forgot-password/ForgotPassword';
import PasswordReset from 'components/authentication/password-reset/PasswordReset';
import EmailVerification from 'components/authentication/email-confirmation/EmailVerification';

import Billing from 'components/settings/billing/Billing';
import APIKeys from 'components/settings/api-keys/APIKeys';
import Profile from 'components/settings/profile/Profile';
import UpdateUser from 'components/settings/profile/UpdateUser';
import UpdateUserPreferences from 'components/settings/profile/UpdateUserPreferences';

import Index from 'components/index/Index';

import Agent from 'components/agents/agent-details/Agent';
import Agents from 'components/agents/agent-list/Agents';
import CreateAgent from 'components/agents/create-agent/CreateAgent';
import UpdateAgent from 'components/agents/update-agent/UpdateAgent';

import { PrivateRoute, AuthRoute } from 'components/common/CustomRoutes';
import { appPaths } from 'config';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  // Must match frontend routes defined in core/config/settings.py on the backend
  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path={appPaths.errors.notFound} element={<Error404 />} />
        </Route>

        <Route element={<AuthRoute element={<AuthLayout />} />}>
          <Route path={appPaths.auth.login} element={<Login />} />
          <Route path={appPaths.auth.register} element={<Registration />} />
          <Route
            path={appPaths.auth.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route
            path={appPaths.auth.resetPassword}
            element={<PasswordReset />}
          />
        </Route>

        <Route element={<PrivateRoute element={<MainLayout />} />}>
          <Route path={appPaths.index} element={<Index />} />
          <Route path={appPaths.agents.list} element={<Agents />} />
          <Route path={appPaths.agents.create} element={<CreateAgent />} />
          <Route path={appPaths.agents.update} element={<UpdateAgent />} />
          <Route path={appPaths.agents.detail} element={<Agent />} />
          <Route path={appPaths.settings.billing} element={<Billing />} />
          <Route path={appPaths.settings.apiKeys} element={<APIKeys />} />
          <Route path={appPaths.settings.profile} element={<Profile />} />
          <Route path={appPaths.settings.updateUser} element={<UpdateUser />} />
          <Route
            path={appPaths.settings.updatePreferences}
            element={<UpdateUserPreferences />}
          />
          <Route
            path={appPaths.auth.verifyEmail}
            element={<EmailVerification />}
          />
        </Route>

        <Route
          path="*"
          element={<Navigate to={appPaths.errors.notFound} replace />}
        />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

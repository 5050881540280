import { appPaths } from 'config';

export const agents = {
  label: 'Agents',
  labelDisable: false,
  children: [
    {
      name: 'Create an Agent',
      icon: 'plus',
      to: appPaths.agents.create,
      active: true
    },
    {
      name: 'My Agents',
      icon: 'robot',
      to: appPaths.agents.list,
      active: true
    }
  ]
};

export const learn = {
  label: 'Learn',
  labelDisable: false,
  children: [
    {
      name: 'Documentation',
      active: true,
      icon: 'book-open',
      children: [
        {
          name: 'Getting Started',
          to: 'https://swarmnode.ai/docs/getting-started',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'REST API',
          to: 'https://swarmnode.ai/docs/api/v1/introduction',
          exact: true,
          active: true,
          newTab: true
        },
        {
          name: 'Python SDK',
          to: 'https://swarmnode.ai/docs/sdk/introduction',
          exact: true,
          active: true,
          newTab: true
        }
      ]
    }
  ]
};

export default [agents, learn];

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Alert } from 'react-bootstrap';
import { resetPassword } from 'helpers/requests/users';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { setFormErrors } from 'helpers/errors';
import { appPaths } from 'config';
import { handleUnexpectedStatusCode } from 'helpers/errors';

const PasswordResetForm = () => {
  const [submittingForm, setSubmittingForm] = useState(false);
  const { userId, resetToken } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm();

  const submitForm = async data => {
    setSubmittingForm(true);
    const r = await resetPassword(userId, resetToken, data.password);
    if (r.success) {
      navigate(appPaths.auth.login);
      toast.success('Password was changed');
    } else {
      if (r.response.status === 400) {
        setFormErrors(r.response.data, setError);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
    setSubmittingForm(false);
  };

  return (
    <Form noValidate onSubmit={handleSubmit(submitForm)}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder="New Password"
          name="password"
          type="password"
          isInvalid={!!errors.password}
          {...register('password', {
            required: 'Enter your password',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters'
            }
          })}
        />
        <Form.Control.Feedback type="invalid">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      {errors.root?.nonFieldError?.message && (
        <Alert variant="danger">{errors.root.nonFieldError.message}</Alert>
      )}

      <Button type="submit" className="w-100" disabled={submittingForm}>
        Set password
      </Button>
    </Form>
  );
};

export default PasswordResetForm;

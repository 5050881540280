import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { getAgents } from 'helpers/requests/agents';
import AgentsInfo from 'components/stats/generic/AgentsInfo';

const Generic = () => {
  const [agentCount, setAgentCount] = useState(0);

  const handleGetAgents = async () => {
    const r = await getAgents();
    if (r.success) {
      setAgentCount(r.response.data.length);
    }
  };

  useEffect(() => {
    handleGetAgents();
  }, []);

  return (
    <Row className="g-3 mb-0">
      <AgentsInfo count={agentCount} />
    </Row>
  );
};

export default Generic;

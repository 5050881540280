import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { appPaths } from 'config';

const ConfirmEmail = () => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={envelope}
      alt="sent"
      width={100}
    />
    <h3>Please check your email!</h3>
    <p>
      We have sent you an email that contains the instructions that explain how
      to reset your password.
    </p>
    <div className="d-flex justify-content-center">
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={appPaths.auth.login}
      >
        Return to login
      </Button>
    </div>
  </>
);

export default ConfirmEmail;

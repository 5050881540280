import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/login/LoginForm';
import { appPaths } from 'config';
import queryString from 'query-string';

const Login = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const nextQueryString = queryParams.next ? `?next=${queryParams.next}` : '';
  const next = queryParams.next || appPaths.index;

  return (
    <>
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in</h5>
        <p className="fs--1 text-600 mb-0">
          or{' '}
          <Link to={`${appPaths.auth.register}${nextQueryString}`}>
            Register
          </Link>
        </p>
      </Flex>
      <LoginForm next={next} />
    </>
  );
};

export default Login;

import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function createAgent(
  name,
  python_version,
  script,
  requirements,
  env_vars
) {
  const EXPECTED_STATUS_CODE = 201;

  try {
    const response = await ax.post(apiPaths.appAgents.agents.create, {
      name: name,
      python_version: python_version,
      script: script,
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function updateAgent(id, payload) {
  const EXPECTED_STATUS_CODE = 204;
  const { name, python_version, script, requirements, env_vars } = payload;

  try {
    const response = await ax.patch(apiPaths.appAgents.agents.update(id), {
      ...(name !== undefined && { name: name }),
      ...(python_version !== undefined && { python_version: python_version }),
      ...(script !== undefined && { script: script }),
      ...(requirements !== undefined && { requirements: requirements }),
      ...(env_vars !== undefined && { env_vars: env_vars })
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAgent(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agents.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function deleteAgent(id) {
  const EXPECTED_STATUS_CODE = 204;
  try {
    const response = await ax.delete(apiPaths.appAgents.agents.delete(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getAgents() {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agents.list);
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function createAgentExecutorJob(agentId) {
  const EXPECTED_STATUS_CODE = 201;
  try {
    const response = await ax.post(
      apiPaths.appAgents.agentExecutorJobs.create,
      {
        agent_id: agentId
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getExecutions(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.executions.list, {
      params: { agent_id: agentId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBuild(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agentBuilds.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getLatestAgentBuilderJob(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appAgents.agentBuilderJobs.latest, {
      params: { agent_id: agentId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

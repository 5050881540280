import React, { useEffect, useState } from 'react';
import { getAgents, deleteAgent } from 'helpers/requests/agents';
import { Card } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Header from 'components/agents/agent-list/Header';
import getColumns from 'components/agents/agent-list/get-columns';
import Table from 'components/agents/agent-list/Table';
import Empty from 'components/agents/agent-list/Empty';
import Loading from 'components/agents/agent-list/Loading';
import { toast } from 'react-toastify';

const Agents = () => {
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(true);

  const removeAgent = id => {
    setAgentList(prevAgentList =>
      prevAgentList.filter(agent => agent.id !== id)
    );
  };

  const handleDeleteAgent = async id => {
    if (window.confirm('Are you sure you want to delete this agent?')) {
      const r = await deleteAgent(id);
      if (r.success) {
        removeAgent(id);
        toast.success('Agent deleted.');
      } else {
        if (r.response.status === 404) {
          toast.error('Agent not found. Maybe it was already deleted?');
        } else {
          handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
        }
      }
    }
  };

  let handleGetAgents = async () => {
    const r = await getAgents();
    if (r.success) {
      setAgentList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetAgents();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Header />
      </Card.Header>
      <Card.Body className="p-0">
        {loading && <Loading />}
        {!loading && agentList.length === 0 && <Empty />}
        {!loading && agentList.length > 0 && (
          <Table columns={getColumns(handleDeleteAgent)} data={agentList} />
        )}
      </Card.Body>
    </Card>
  );
};

export default Agents;

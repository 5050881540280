import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import RegistrationForm from 'components/authentication/registration/RegistrationForm';
import { appPaths } from 'config';
import queryString from 'query-string';

const Registration = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const nextQueryString = queryParams.next ? `?next=${queryParams.next}` : '';
  const next = queryParams.next || appPaths.index;

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="modalLabel">Register</h5>
        </Col>
        <Col xs="auto">
          <p className="fs--1 text-600 mb-0">
            or{' '}
            <Link to={`${appPaths.auth.login}${nextQueryString}`}>Log in</Link>
          </p>
        </Col>
      </Row>
      <RegistrationForm next={next} />
    </>
  );
};

export default Registration;

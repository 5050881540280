import React from 'react';
import PasswordResetForm from 'components/authentication/password-reset/PasswordResetForm';

const PasswordReset = () => {
  return (
    <>
      <div className="text-center mb-4">
        <h5 className="mb-0">Choose a new password</h5>
        <small>Enter a new password for your account.</small>
      </div>
      <PasswordResetForm />
    </>
  );
};

export default PasswordReset;

import React, { useState } from 'react';
import ConfirmEmail from 'components/authentication/forgot-password/ConfirmEmail';
import ForgotPasswordForm from 'components/authentication/forgot-password/ForgotPasswordForm';

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);

  return emailSent ? (
    <ConfirmEmail />
  ) : (
    <>
      <div className="text-center mb-4">
        <h5 className="mb-0"> Forgot your password?</h5>
        <small>Enter your email and we'll send you a reset link.</small>
      </div>
      <ForgotPasswordForm setEmailSent={setEmailSent} />
    </>
  );
};

export default ForgotPassword;
